.bg-app{
  .mat-dialog-container{
    background: transparent;
  }
}
.dialog-wrapper{
  margin: 0;
  max-width: 100%;
  border-radius: 1.0rem;
  position: relative;
  .close {
    position: absolute;
    right: -.5rem;
    top: -.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h1{
    font-size: 1.2rem;
    font-weight: 500;
  }
  .dialog-content{
    .mat-form-field-wrapper {
      padding: .5rem 0 1.0rem;
    }
  }
  .dialog-footer{
    padding-top: 1rem;
    button:not(:last-child){
      margin-right: 1.5rem;
    }
  }
}