@mixin utility-mixin($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  
  /*
  1.) Global Utilties
  -------------------------------------*/
  a {
    color: mat-color($primary, 700);
  }

  // .material-icons {
  //   font-size: 2.1rem;
  // }

  .shadow {
    // box-shadow: 0 .4rem .6rem rgba(0,0,0,.2);
    box-shadow: 0 0rem 4rem rgb(0 0 0 / 18%);
  }
  
  .vHidden{
    visibility: hidden;
  }

  .spacer {
    flex: 1 1 auto;
  }
  
  .opacity {
    opacity: 0.8;
  }

  .full-width,
  .full-width img {
    width: 100%;
  }

  .full-height {
    min-height: 100vh;
  }

  .pointer-events-none {
    pointer-events: none;
  }
  .pointer {
    cursor: pointer;
  }


  /* Alignments ----------------*/
  .align-center {
    text-align: center;
  }
  .align-right {
    text-align: right;
  }



  /* Positions ----------------*/
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }



  /* Flex ----------------*/
  .flex-item-center {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .flex-column {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .flex-middle-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .flex-middle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex-space-between {
    display: flex;
    justify-content: space-between;
  }



  .inputTxt{
    border-radius: 0.5rem;
    padding: 1.23rem 1rem 1.27rem;
    display: flex;
    margin-bottom: .5rem;
    font-family: "Cera Pro";
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: -0.3px;
    border: 0;
    outline: 0;
    color: mat-color($foreground, text, 0.8);
    width: 100%;
    box-sizing: border-box;
    &.ng-invalid.ng-touched{
      border: 1px solid rgb(255 0 0 / 60%);
      background: rgb(255 0 0 / 8%);
      box-shadow: 0 0 8px rgb(255 0 0 / 35%);
    }
  }

  .form-item{
    margin-bottom: 1rem;
    &:last-child{
      margin-bottom: 2rem;
    }
    label{
      font-size: 1.2rem;
      opacity: .5;
      margin-bottom: 0.7rem;
      display: block;
    }
  }
  /*
  2.) Global Elements
  -------------------------------------*/
  // .pro-msg{
  //   border: .1rem dashed mat-color($primary, 700);
  //   background: mat-color($primary, 700, .1);
  // }


  .more-btn {
    padding: 0 .5rem;
  }
  .more-btn .mat-icon {
    background: mat-color($primary, 700);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: .3rem;
    padding-left: .2rem;
  }


  button{
    min-height: 4rem;
    outline: none;
    cursor: pointer;
    font-family: "Cera Pro";
    .mat-spinner{
      display: inline-block;
      top: -.3rem;
      position: absolute;
      top: calc(50% - 1.0rem);
      left: calc(50% - 1.0rem);
    }
    &.op-btn{
      opacity: .5;
      transition: .3s ease all;
      &:hover{
        opacity: 1;
      }
    }
  }
  .mat-stroked-button:not([disabled]) {
      border-color: inherit;
  }
  .btn{
      &.yellow{
        background: #ffcc00;
        color: rgb(92, 35, 35);
      }
  }



   // visualCue starts
   .highlighting {
    // animation: highlightLoop .5s ease infinite backwards;
    animation: 1.3s ease-out infinite backwards highlightLoop !important;
    &.btn{
      animation: 1.3s ease-out infinite backwards highlightBtnLoop !important;
    }
    &.low{
      animation: 1.3s ease-out infinite backwards highlightLoopLow !important;
    }
  }
  .scaleHighlight{
    animation: 1.3s ease 2 backwards scale !important;
    backface-visibility: hidden;
  }
  @keyframes scale {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.02); 
      background: #02be6e;
      color: #fff;
    }
  }
  @keyframes highlightLoop {
    0%,
    100% {
      box-shadow: 0 0 4.0rem mat-color($primary, 500, 0);
    }
    50% {
      box-shadow: 0 0 4.0rem mat-color($primary, 500, 0.9);
    }
  }
  @keyframes highlightLoopLow {
    0%,
    100% {
      box-shadow: 0 0 0 mat-color($primary, 500, 0);
    }
    50% {
      box-shadow: 0 0 7.0rem mat-color($primary, 500, 0.3);
    }
  }
  @keyframes highlightBtnLoop {
    0%,
    100% {
      box-shadow: 0 0 4rem mat-color($accent, 500, 0);
    }
    50% {
      box-shadow: 0 0 4rem mat-color($accent, 500, .8);
    }
  }
  
  // visualCue starts


  .make-default {
    background: rgb(59 202 92 / 18%);
    border: .1rem solid #3bca5c;
    border-radius: 3rem;
    padding: 0 1.3rem;
    line-height: 1;
    height: 2.6rem;
    font-size: 1.1rem;
    align-self: center;
  }
  
  .warning-note {
    background: hsl(43 96% 58% / 0.15);
    padding: 2.0rem;
    border-radius: 1.0rem;
    line-height: 1.5;
    font-weight: 600;
    border: .1rem solid #fbc02d;
    display: flex;
    margin: 1.5rem 0;
    .txt {
      opacity: .9;
      font-weight: 400;
    }
    .icon {
      color: #fbc02d !important;
    }
    .message {
      color: #fbc02d !important;
    }
  }

  .error-note {
    background: rgb(193 15 48 / 0.15);
    padding: 2.0rem;
    border-radius: 1.0rem;
    line-height: 1.5;
    font-weight: 600;
    border: .1rem solid #c10f30;
    display: flex;
    margin: 1.5rem 0;
    .icon {
      color: #fd153f !important;
    }
    .message {
      color: #fd153f !important;
    }
  }

  .success-note {
    background: rgb(7 247 104 / 0.15);
    padding: 2.0rem;
    border-radius: 1.0rem;
    line-height: 1.5;
    font-weight: 600;
    border: .1rem solid #0cd05b;
    display: flex;
    margin: 1.5rem 0;
    .icon {
      color: #0ae05f !important;
    }
    .message {
      color: #0ae05f !important;
    }
  }

  /*
  3.) Loops
  -------------------------------------*/
  @for $i from 0 through 9 {
    .opacity-#{$i*1} {
      opacity: #{$i * 0.1};
    }
    .bg-app-#{$i*1} {
      background: mat-color($background, app-bar, $i * 0.1);
    }
    .bg-card-#{$i*1} {
      background: mat-color($background, card, $i * 0.1);
    }
    .bg-darkest-#{$i*1} {
      background: mat-color($background, hover, $i * 0.1);
    }
    .bg-status-#{$i*1} {
      background: mat-color($background, status-bar, $i * 0.1);
    }
    .bg-primary-#{$i*1} {
      background: mat-color($primary, 700, $i * 0.1);
    }
    .bg-accent-#{$i*1} {
      background: mat-color($accent, $i * 0.1);
    }
    .bg-warn-#{$i*1} {
      background: mat-color($warn, $i * 0.1);
    }
    .outline-#{$i*1} {
      border-color:  mat-color($foreground, slider-off, $i * 0.1);
    }
    .outline-primary-#{$i*1} {
      border-color: mat-color($primary, 700, $i * 0.1);
    }
    .outline-accent-#{$i*1} {
      border-color: mat-color($accent, 500, $i * 0.1);
    }
    .outline-warn-#{$i*1} {
      border-color: mat-color($warn, 500, $i * 0.1);
    }
    .background-#{$i*1} {
      background: mat-color($background, background, $i * 0.1);
    }
    .foreground-#{$i*1} {
      color: mat-color($foreground, text, $i * 0.1);
    }
    .primary-#{$i*1} {
      color: mat-color($primary, 700, $i * 0.1);
    }
    .accent-#{$i*1} {
      color: mat-color($accent, $i * 0.1);
    }
    .warn-#{$i*1} {
      color: mat-color($warn, $i * 0.1);
    }
  }


  /*
  4.) Backgrounds
  -------------------------------------*/
  .bg-primary {
    background: mat-color($primary, 700);
  }
  .bg-accent {
    background: mat-color($accent);
  }
  .bg-warn {
    background: mat-color($warn);
  }
  .bg-app {
    background: mat-color($background, app-bar);
  }
  .bg-card {
    background: mat-color($background, card);
  }
  .bg-darkest {
    background: mat-color($background, hover);
  }
  .bg-status {
    background: mat-color($background, status-bar);
  }
  .background {
    background: mat-color($background, background);
  }


  /*
  5.) Foregrounds
  -------------------------------------*/
  .foreground {
    color: mat-color($foreground, text);
  }
  .text-default {
    color: mat-color($background, background);
  }
  .primary {
    color: mat-color($primary, 700);
  }
  .accent {
    color: mat-color($accent);
  }
  .warn {
    color: mat-color($warn);
  }
  .color-red {
    color: #fe3451;
  }
  .color-green {
    color: #02be6e;
  }


  /*
  6.) Outlines
  -------------------------------------*/
  .outline {
    border: .1rem solid mat-color($foreground, slider-off);
  }
  .outline-primary {
    border-color: mat-color($primary, 700);
  }
  .border-primary {
    border: .1rem solid mat-color($primary, 700);
  }
  .outline-warn {
    border-color: mat-color($warn, 700);
  }
  .outline-top {
    border-top: .1rem solid mat-color($foreground, slider-off);
  }
  .outline-bottom {
    border-bottom: .1rem solid mat-color($foreground, slider-off);
  }
  .outline-color {
    border-color: mat-color($foreground, slider-off, 0.1) !important;
  }

  
  


  
} // utility-mixin Ends