// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~@angular/cdk/overlay-prebuilt.css";
// Plus imports for other components in your app.

@import "assets/css/includes";

$typography-config: mat-typography-config(
    $font-family: "Cera Pro",
    $body-1: mat-typography-level(16px, 1.4, 400),
    /* Base body text */ $body-2: mat-typography-level(16px, 1.4, 600),
    /* Bolder body text. */ $headline: mat-typography-level(3rem, 1.2, 500),
    /* h1 */ $title: mat-typography-level(3rem, 1.2, 500),
    /* h2 */ $subheading-2: mat-typography-level(2rem, 1.5rem, 500),
    /* h3 */ $subheading-1: mat-typography-level(18px, 1.5, 400),
    /* h4 */
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($typography-config);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tastio-dark-primary: mat-palette($mat-yellow, 700);
$tastio-dark-accent: mat-palette($mat-red, 600);

$tastio-light-primary: mat-palette($mat-red, 700);
$tastio-light-accent: mat-palette($mat-yellow, 700);
//$tastio-accent: mat-palette($mat-red, A200, A100, A400);
// $tastio-secondary: mat-palette($mat-amber, A700);

// The warn palette is optional (defaults to red).
$tastio-warn: mat-palette($mat-red);
$theme: mat-dark-theme($tastio-dark-primary, $tastio-dark-accent, $tastio-warn);
@include angular-material-theme($theme);
@include includes-mixin($theme);
ngx-skeleton-loader .loader {
    border-radius: 5px;
    background-color: #323232;
    border: 1px solid #323232;
    animation-duration: 2s;
}
.bg-default {
    background-color: #000;
}
.dark-theme {
    $theme: mat-dark-theme($tastio-dark-primary, $tastio-dark-accent, $tastio-warn);
    @include angular-material-theme($theme);
    @include includes-mixin($theme);
    ngx-skeleton-loader .loader {
        border-radius: 5px;
        background-color: #323232;
        border: 1px solid #323232;
        animation-duration: 2s;
    }
    .bg-default {
        background-color: #000;
    }
}

.light-theme {
    $theme: mat-light-theme($tastio-light-primary, $tastio-light-accent, $tastio-warn);
    @include angular-material-theme($theme);
    @include includes-mixin($theme);
    ngx-skeleton-loader .loader {
        border-radius: 5px;
        animation-duration: 2s;
    }
    .bg-default {
        background-color: #fff;
    }
}

/* You can add global styles to this file, and also import other style files */

:root {
    font-size: 10px;
}
html,
body {
    min-height: 100%;
    top: 0 !important;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.font-montserrat {
    font-family: "Montserrat", sans-serif;
}

#version{
    display: none;
}
#notification {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 200px;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.hidden {
    display: none;
}

#close-button {
    min-height: 3rem;
    outline: none;
    cursor: pointer;
    font-family: "Cera Pro";
    border-radius: 12px;
    border: none;
}
#restart-button {
    min-height: 3rem;
    outline: none;
    cursor: pointer;
    font-family: "Cera Pro";
    border-radius: 12px;
    border: none;
    background: green;
    color: white;
}
