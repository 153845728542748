// h1,
// h2,
// h3,
// h4 {
// font-weight: 600;
// margin: 0;
// }
h1,
h2 {
    margin-bottom: 1.8rem;
}
h3 {
    margin-bottom: 1.5rem;
}
h4 {
    margin-bottom: .3rem;
}
p {
    opacity: 0.8;
}