
// @media screen and (max-width: 4000px) {
//     :root { font-size: 45px !important}
// }
// @media screen and (max-width: 3000px) {
//     :root { font-size: 40px !important}
// }
@media screen and (min-width: 1600px) {
    :root { font-size: 13px !important}
}
// @media screen and (min-width: 2100px) {
//     :root { font-size: 24px !important}
// }
@media screen and (min-width: 3000px) {
    :root { font-size: 21px !important}
}
// @media screen and (min-width: 4000px) {
//     :root { font-size: 45px !important}
// }
// @media screen and (max-width: 1920px) {
//     :root { font-size: 16px !important}
// }
// @media screen and (max-width: 1600px) {
//     :root { font-size: 12px !important}
// }
// @media screen and (max-width: 1100px) {
//     :root { font-size: 16px !important}
// }
// @media screen and (max-width: 767px) {
//     :root { font-size: 8px !important}
// }