/*
  Global Classes
-------------------------------------*/
#wrapper {
  position: relative;
}

.container {
  width: 95%;
  margin: 0 auto;
}

.mat-option strong {
  font-weight: 500;
}
/*
  Header Bar
-------------------------------------*/
.header-bar{
  padding: 0 1.3rem;
  position: relative;
  height: 6rem;
  display: flex;
  align-items: center;
  .title{
    position: absolute;
    font-size: 1.5rem;
    margin: 0;
    font-weight: 500;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .center{
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    h1{
      font-size: 2rem;
      white-space: nowrap;
      max-width: 68vw;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 auto;
      small {
        display: block;
        font-weight: 300;
        font-size: 0.6em;
        opacity: 0.5;
      }
    }
  }
  app-lang{
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
  }
}



/*
  Cart Payment Modal
-------------------------------------*/
.card-payment-container {
  min-height: 25.0rem;
  min-width: 37.0rem;
  position: relative;
  margin: -24px;
  padding: 1.4rem 2.4rem;

  .model-header {
    padding: 0;
    display: flex;
    h2 {
      font-size: 2.6rem;
      margin: .5rem 0 1.5rem;
    }
  }
  .model-body {
    display: flex;
    flex-direction: column;
    max-height: 30rem;
    overflow: auto;
  }
  .col-left {
    margin-right: 1rem;
  }
  .close {
    position: absolute;
    right: 1.6rem;
    top: 1.5rem;
    line-height: 2.6;
  }
  
  .btn {
    font-size: 1.6rem;
    line-height: 1;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    ::ng-deep{
      .mat-button-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        span{
          margin: 0 .5rem;
        }
      }
    }
  }
}





/*
  Bottom Strip
-------------------------------------*/
.bottom-strip{
  display: flex;
  align-items: center;
  height: 6rem;
  width: 100%;
  .back{
    margin-right: 2rem;
  }
  .flex{
    align-items: center;
    .btn{
      font-size: 1.6rem;
      line-height: 1;
      border-radius: 0.5rem;
      padding: 1rem 1.5rem;
    }
    .qty{
      margin-right: 1em;
    }
    .price{
      margin: 0 auto 0 0;
      font-size: 1.8rem;
    }
  }
} 


.mat-icon-button{
  &.back {
    font-size: 1.2rem;
    width: 3.5em;
    height: 3.5em;
    line-height: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: unset;
    .mat-icon{
      font-size: 1.8em;
      height: 1.8em;
      width: 1.8em;
      line-height: 1.5;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}


.qty{
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  padding: .2rem 0;
  border-radius: .8rem;
  border: .1rem solid;
  .ico{
    margin: 0 .5rem;
    font-size: 1.2rem;
    width: 3em;
    height: 3em;
    line-height: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: unset;
    .mat-icon{
      font-size: 1.6em;
      height: 1.6em;
      width: 1.6em;
      line-height: 1.5;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}




/*
  Custom Scrollbar
-------------------------------------*/
/* total width */
.custom-scrollbar::-webkit-scrollbar {
  // background-color:rgba(255, 255, 255, 0.2);
  width:.6rem;
  height: .6rem;
}

/* background of the scrollbar except button or resizer */
.custom-scrollbar::-webkit-scrollbar-track {
  background-color:rgba(0, 0, 0, 0.01);
}
.custom-scrollbar::-webkit-scrollbar-track:hover {
  background-color:rgba(0, 0, 0, 0.05)
}

/* scrollbar itself */
.custom-scrollbar::-webkit-scrollbar-thumb {
  // background-color:#babac0;
  background-color:transparent;
  border-radius:1.6rem;
  margin: 0 .2rem;
  border:.5rem solid transparent;
  transition: .9s ease all;
}
// .custom-scrollbar::-webkit-scrollbar-thumb:hover {
//     background-color:rgba(0, 0, 0, 1)
// }
.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color:rgba(0, 0, 0, 0.1)
}
.custom-scrollbar::-webkit-scrollbar-button {display:none}

.custom-scrollbar {
  overflow: auto;
  padding-bottom: .8rem;
}



/*
  Responsive style
-------------------------------------*/

@media (max-width: 1024px) {

}

@media (max-width: 767px) {
  .custom-scrollbar {
    -webkit-overflow-scrolling: touch;
  }

  /* total width */
  .custom-scrollbar::-webkit-scrollbar {
    width:1.2rem;
  }

  /* background of the scrollbar except button or resizer */
  .custom-scrollbar::-webkit-scrollbar-track,
  .custom-scrollbar::-webkit-scrollbar-track:hover,
  .custom-scrollbar::-webkit-scrollbar-thumb,
  .custom-scrollbar:hover::-webkit-scrollbar-thumb {
    display: none;
  }
  .card-payment-container{
    min-width: auto;
  }
}