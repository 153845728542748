@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes rotatecircle {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
    }
}

@keyframes rotatecircle {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes trLoop {
	0%,
	100% {
		transform: translateY(0)
	}
	50% {
		transform: translateY(1.5rem)
	}
}
@keyframes trLoopV {
	0%,
	100% {
		bottom: 50%;
	}
	50% {
		bottom: 48%;
	}
}
