.m-0 {
    margin: 0!important
}

.mt-0,
.my-0 {
    margin-top: 0!important
}

.mr-0,
.mx-0 {
    margin-right: 0!important
}

.mb-0,
.my-0 {
    margin-bottom: 0!important
}

.ml-0,
.mx-0 {
    margin-left: 0!important
}

.m-1 {
    margin: .25rem!important
}

.mt-1,
.my-1 {
    margin-top: .25rem!important
}

.mr-1,
.mx-1 {
    margin-right: .25rem!important
}

.mb-1,
.my-1 {
    margin-bottom: .25rem!important
}

.ml-1,
.mx-1 {
    margin-left: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.mt-2,
.my-2 {
    margin-top: .5rem!important
}

.mr-2,
.mx-2 {
    margin-right: .5rem!important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem!important
}

.ml-2,
.mx-2 {
    margin-left: .5rem!important
}

.m-3 {
    margin: 1rem!important
}

.mt-3,
.my-3 {
    margin-top: 1rem!important
}

.mr-3,
.mx-3 {
    margin-right: 1rem!important
}

.mb-3,
.my-3 {
    margin-bottom: 1rem!important
}

.ml-3,
.mx-3 {
    margin-left: 1rem!important
}

.m-4 {
    margin: 1.5rem!important
}

.mt-4,
.my-4 {
    margin-top: 1.5rem!important
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem!important
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem!important
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem!important
}

.m-5 {
    margin: 3rem!important
}

.mt-5,
.my-5 {
    margin-top: 3rem!important
}

.mr-5,
.mx-5 {
    margin-right: 3rem!important
}

.mb-5,
.my-5 {
    margin-bottom: 3rem!important
}

.ml-5,
.mx-5 {
    margin-left: 3rem!important
}

.m-auto {
    margin: auto!important
}

.mt-auto,
.my-auto {
    margin-top: auto!important
}

.mr-auto,
.mx-auto {
    margin-right: auto!important
}

.mb-auto,
.my-auto {
    margin-bottom: auto!important
}

.ml-auto,
.mx-auto {
    margin-left: auto!important
}