@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap');
@font-face {
    font-family: "Cera Pro";
    src: url("../fonts/CeraPro/CeraPro-Thin.woff2") format("woff2"),
        url("../fonts/CeraPro/CeraPro-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Cera Pro";
    src: url("../fonts/CeraPro/CeraPro-ThinItalic.woff2") format("woff2"),
        url("../fonts/CeraPro/CeraPro-ThinItalic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Cera Pro";
    src: url("../fonts/CeraPro/CeraPro-LightItalic.woff2") format("woff2"),
        url("../fonts/CeraPro/CeraPro-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Cera Pro";
    src: url("../fonts/CeraPro/CeraPro-Light.woff2") format("woff2"),
        url("../fonts/CeraPro/CeraPro-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Cera Pro";
    src: url("../fonts/CeraPro/CeraPro-Regular.woff2") format("woff2"),
        url("../fonts/CeraPro/CeraPro-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Cera Pro";
    src: url("../fonts/CeraPro/CeraPro-Italic.woff2") format("woff2"),
        url("../fonts/CeraPro/CeraPro-Italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Cera Pro";
    src: url("../fonts/CeraPro/CeraPro-Medium.woff2") format("woff2"),
        url("../fonts/CeraPro/CeraPro-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Cera Pro";
    src: url("../fonts/CeraPro/CeraPro-MediumItalic.woff2") format("woff2"),
        url("../fonts/CeraPro/CeraPro-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Cera Pro";
    src: url("../fonts/CeraPro/CeraPro-Bold.woff2") format("woff2"),
        url("../fonts/CeraPro/CeraPro-Bold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Cera Pro";
    src: url("../fonts/CeraPro/CeraPro-BoldItalic.woff2") format("woff2"),
        url("../fonts/CeraPro/CeraPro-BoldItalic.woff") format("woff");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Cera Pro";
    src: url("../fonts/CeraPro/CeraPro-Black.woff2") format("woff2"),
        url("../fonts/CeraPro/CeraPro-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Cera Pro";
    src: url("../fonts/CeraPro/CeraPro-BlackItalic.woff2") format("woff2"),
        url("../fonts/CeraPro/CeraPro-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}
