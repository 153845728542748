@mixin snackbar-mixin() {
    // .mat-snack-bar-container{
    //     transform: scale(2.2) translateY(-1rem) !important;
    // }
      
    .mat-snack-bar-container {
        border-radius: .4rem;
        box-sizing: border-box;
        display: block;
        margin: 1.4rem;
        max-width: 33vw;
        min-width: 34.4rem;
        padding: 1.4rem 1.6rem;
        min-height: 4.8rem;
        transform-origin: center;
    }
    .success-snackbar,
    .error-snackbar,
    .info-snackbar{
        background: #292929 !important;
        color: whitesmoke !important;
        position: relative;
        overflow: hidden;
        &::before{
            content: "";
            width: 14rem;
            height: 9rem;
            border-radius: 50%;
            background: #01dc6a;
            position: absolute;
            left: -4rem;
            z-index: -1;
            top: -1rem;
            filter: blur(5.6rem);
        }
    }
    .success-snackbar {
        &::before{
            background: #01dc6a;
        }
        .mat-icon{
            color: #01dc6a;
        }
    }
    .error-snackbar {
        &::before{
            background: #fe3451;
        }
        .mat-icon{
            color: #fe3451;
        }
    }
    .info-snackbar {
        &::before{
            background: #ffcc00;
        }
        .mat-icon{
            color: #ffcc00;
        }
    }
}




//   // custom snacbar starts
//   .success-snackbar,
//   .error-snackbar,
//   .info-snackbar{
//     background: #292929 !important;
//     color: whitesmoke !important;
//     position: relative;
//     overflow: hidden;
//     &::before{
//       content: "";
//       width: 14rem;
//       height: 9rem;
//       border-radius: 50%;
//       background: #01dc6a;
//       position: absolute;
//       left: -4rem;
//       z-index: -1;
//       top: -1rem;
//       filter: blur(5.6rem);
//     }
//   }
//   .success-snackbar {
//     background: rgb(20, 150, 20);
//     color: whitesmoke !important;
//       &::before{
//           background: #01dc6a;
//       }
//       .mat-icon{
//           color: #01dc6a;
//       }
//   }
//   .error-snackbar {
//     background: rgb(207, 49, 49);
//     color: whitesmoke !important;
//       &::before{
//         background: #fe3451;
//       }
//       .mat-icon{
//           color: #fe3451;
//       }
//   }
//   .info-snackbar {
//     background: #d39b1f;
//     color: whitesmoke !important;
//       &::before{
//         background: #ffcc00;
//       }
//       .mat-icon{
//         color: #ffcc00;
//       }
//   }

//   // custom snacbar ends